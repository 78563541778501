import React from "react"
import { Col, Accordion } from "react-bootstrap"

const Privacy = () => {
  return (
    <Accordion>
      <Col className="pb-2">
        <Accordion.Item eventKey="701">
          <Accordion.Header>Is my Identity secured?</Accordion.Header>
          <Accordion.Body>
            Your identity is handled with our Cloud Identity Provider, we do not
            keep or save your identity nor your password. the Identity Cloud
            Provider takes care of this, hashes your password into an encrypted
            one, and saves your identity in a secure database that is just
            designed for this task.
          </Accordion.Body>
        </Accordion.Item>
      </Col>
      <Col className="pb-2">
        <Accordion.Item eventKey="702">
          <Accordion.Header>
            Do you have an access to the buyers data or PII Access?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              No, your buyer data (Name, address) is not provided to us, and we
              never keep them.
            </p>
            <p>
              The buyer data is useful for FBM sellers to automate printing
              shipping labels, we do not provide such service.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Col>
      <Col className="pb-2">
        <Accordion.Item eventKey="703">
          <Accordion.Header>
            What do you do with my authorization code and where do you save it?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              When you authorize us to access your account, amazon provides us
              with an authorization code. This code can ONLY be used by us, if
              some other developer tried the same code it will not work.
            </p>
            <p>
              Your code is saved in a cloud database after hashing it with a key
              that no one can access.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Col>
    </Accordion>
  )
}

export default Privacy

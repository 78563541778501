import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { Link } from "gatsby"

import { Container, Row, Col, Nav,  Tab } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import { FcDataProtection } from "react-icons/fc"
import { RiGitRepositoryPrivateLine } from "react-icons/ri"
import { MdApps } from "react-icons/md"
import { MdAccountCircle } from "react-icons/md"
import { RiMoneyDollarBoxLine } from "react-icons/ri"
import { MdMoneyOff } from "react-icons/md"

import DataProtection from "../components/Faq/DataProtection"
import Privacy from "../components/Faq/Privacy"
import Azszeroapp from "../components/Faq/Azszeroapp"
import AzszeroAccount from "../components/Faq/AzszeroAccount"
import Prices1 from "../components/Faq/Prices1"
import Prices2 from "../components/Faq/Prices2"

const Faq = () => {
  return (
    <Layout>
      <Seo
        title="AZSzero -- FAQ"
        description="Frequently Asked Questions about AZSzero"
      />

      <Container className="p-5">
        <div className="text-center pb-5" style={{ position: "relative" }}>
          <Styledh1 className="display-4">FAQ</Styledh1>
          <StaticImage
            src="../assets/images/abou-bg.jpg"
            alt="Amazon FBA"
            placeholder="tracedSVG"
            layout="fullWidth"
            aspectRatio={6 / 1}
            style={{ zIndex: "-1" }}
          ></StaticImage>
        </div>

        <Row className="mx-auto pb-5">
          <Col>
            <h2 className="text-center pb-4 ">FREQUENTLY ASKED QUESTIONS</h2>
            <p>
              Here are some of the most common questions and their answers, If
              you can't find your ansower here, please{" "}
              <Link to="/Contacts">Contacs Us.</Link>:
            </p>
          </Col>
        </Row>

        <Row className="mx-auto">
          <Col>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <Col md={12} lg={4}>
                  <FaqNav>
                    <Nav variant="pills" className="flex-column ">
                      <Nav.Item className="first-item">
                        <Nav.Link className="text-center">FAQ TOPICS</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="nav-link">
                        <Nav.Link eventKey="first">
                          <FcDataProtection />
                          Data Protection
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="nav-link">
                        <Nav.Link eventKey="second">
                          <RiGitRepositoryPrivateLine />
                          Privacy
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="nav-link">
                        <Nav.Link eventKey="third">
                          <MdApps />
                          AZSzero App
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="nav-link">
                        <Nav.Link eventKey="fourth">
                          <MdAccountCircle />
                          AZSzero Account
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="nav-link">
                        <Nav.Link eventKey="fifth">
                          <RiMoneyDollarBoxLine />
                          Payment
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="nav-link">
                        <Nav.Link eventKey="sixth">
                          <MdMoneyOff />
                          Free Offer
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </FaqNav>
                </Col>
                <Col md={12} lg={8}>
                  <StyledTab>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <DataProtection />
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Privacy />
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <Azszeroapp />
                      </Tab.Pane>
                      <Tab.Pane eventKey="fourth">
                        <AzszeroAccount />
                      </Tab.Pane>
                      <Tab.Pane eventKey="fifth">
                        <Prices1 />
                      </Tab.Pane>
                      <Tab.Pane eventKey="sixth">
                        <Prices2 />
                      </Tab.Pane>
                    </Tab.Content>
                  </StyledTab>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

const Styledh1 = styled.h1`
  padding: 30px;
  color: slategray;
  margin-bottom: -50px;
  z-index: 10;
  font-weight: 700;
`

const FaqNav = styled.div`
  flex-direction: column;
  margin: 0 0 32px;
  border-radius: 2px;
  border: 1px solid #ddd;
  box-shadow: 0 1px 5px rgba(85, 85, 85, 0.15);

  .first-item {
    background-color: #847c7c !important;
    cursor: auto !important;
    > a {
      color: #efe4e4 !important;
      padding: 20px !important;
      font-weight: bold;
      font-size: x-large;
    }
  }

  .nav-link {
    padding: 0px;
  }

  .nav-item {
    position: relative;
    display: block;
    margin: 0;
    background-color: #fff;
    border: 0;
    border-bottom: 1px solid #ddd;
    border-radius: 0;
    color: #616161;
    transition: background-color 0.2s ease;
    cursor: pointer;

    > a {
      color: #616161;
      padding: 15px;
    }

    & :hover {
      background-color: #f6f6f6;
    }

    & .active {
      background-color: #f6f6f6;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.87);
    }

    & :last-of-type {
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      border-bottom: 0;
    }

    > a > svg {
      margin-right: 15px;
      font-size: 18px;
      position: relative;
    }
  }
`
const StyledTab = styled.div`
  box-shadow: 0 1px 5px rgba(85, 85, 85, 0.15);

  & div > div > div > div :last-of-type {
    border-bottom-right-radius: 0rem;
    border-bottom-left-radius: 0rem;
  }
  & div > div > div > div :first-of-type {
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
  }

  &
    .tab-content
    > div
    > .accordion
    > .accordion-item
    > .accordion-header
    > button {
    background-color: #f6f6f6;
  }
`

export default Faq

import React from "react"
import { Col, Accordion } from "react-bootstrap"

const AzszeroAccount = () => {
  return (
    <Accordion>
      <Col className="pb-2">
        <Accordion.Item eventKey="205">
          <Accordion.Header>
            Can I add another marketplace to my account?
          </Accordion.Header>
          <Accordion.Body>
            Yes, you can add as many marketplaces as you have on the same
            account.
          </Accordion.Body>
        </Accordion.Item>
      </Col>
      <Col className="pb-2">
        <Accordion.Item eventKey="206">
          <Accordion.Header>
            Can I add another marketplace on a different account?
          </Accordion.Header>
          <Accordion.Body>
            We do not recommend that, once you do this, you can not add it back
            to your original account, as no different users can own the same
            marketplace.
          </Accordion.Body>
        </Accordion.Item>
      </Col>
      <Col className="pb-2">
        <Accordion.Item eventKey="207">
          <Accordion.Header>
            I can't add a marketplace to my account, The error message says that
            the marketplace is owned by another seller.
          </Accordion.Header>
          <Accordion.Body>
            If you added a marketplace to an account, you can not add it again
            to another, for security reasons, the system by design does not
            allow that. you can contact us with your situation for further help.
          </Accordion.Body>
        </Accordion.Item>
      </Col>
      <Col className="pb-2">
        <Accordion.Item eventKey="208">
          <Accordion.Header>
            Can I have multiple Seller Central Account registered as one account
            in AZSzero?
          </Accordion.Header>
          <Accordion.Body>
            If you were authorized by Amazon to have multiple accounts, we can
            assist you to accomplish that.
          </Accordion.Body>
        </Accordion.Item>
      </Col>
      <Col className="pb-2">
        <Accordion.Item eventKey="209">
          <Accordion.Header>
            Can I create a sub-user for my employee to access the account?
          </Accordion.Header>
          <Accordion.Body>
            Currently, we do not support this feature, you can contact us to see
            if we can add this on the roadmap.
          </Accordion.Body>
        </Accordion.Item>
      </Col>
      <Col className="pb-2">
        <Accordion.Item eventKey="210">
          <Accordion.Header>Can I change my Email Address?</Accordion.Header>
          <Accordion.Body>
            No, once you used the email address, you can not change it, we
            understand that this might upset some, but this is one feature that
            if we add will reflect negatively on multiple factors of the app.
          </Accordion.Body>
        </Accordion.Item>
      </Col>
    </Accordion>
  )
}

export default AzszeroAccount

import React from "react"
import { Col, Accordion } from "react-bootstrap"

const Azszeroapp = () => {
  return (
    <Accordion>
      <Col className="pb-2">
        <Accordion.Item eventKey="200">
          <Accordion.Header>What does AZSzero do exactly?</Accordion.Header>
          <Accordion.Body>
            AZSzero prepares the most used reports and creates notifications for
            critical tasks, it's concentrated on what Amazon sellers usually
            use. It helps you organize your workflow, prevents you from sudden
            problems, and allows you to expand your business. Amazon's dashboard
            is huge and bulky, there are FBA sellers that have never visited
            parts of Amazon dashboard, yet amazon has to have these
            functionalities. Almost all of Amazon FBA seller usually uses less
            than 5% of the total Amazon's dashboard, the rest is visited once in
            a blue moon. So when you use AZSzero, you only see the most
            important and valuable data for your business. Please visit the
            Features page for a complete list of App features.
          </Accordion.Body>
        </Accordion.Item>
      </Col>
      <Col className="pb-2">
        <Accordion.Item eventKey="201">
          <Accordion.Header>What Benifits do I get?</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>You will have a much clearer view of your business.</li>
              <li>You will save a tremendous amount of time.</li>
              <li>You will get alerts on critical tasks.</li>
              <li>You will have an organized plan of what to do next.</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Col>{" "}
      <Col className="pb-2">
        <Accordion.Item eventKey="202">
          <Accordion.Header>Who can use AZSzero?</Accordion.Header>
          <Accordion.Body>
            All FBA Sellers can use the app, the difference is how much the app
            can give you, the bigger you are, the more benefits you get, if you
            are a micro seller, you probably won't get the maximum benefits. If
            you are FBM seller, you will only miss Inventory management.
          </Accordion.Body>
        </Accordion.Item>
      </Col>
      <Col className="pb-2">
        <Accordion.Item eventKey="203">
          <Accordion.Header>What Markets AZSzero supports?</Accordion.Header>
          <Accordion.Body>
            AZSzero supports the folowing markets:
            <ul>
              <li>USA</li>
              <li>Canada</li>
              <li>Mexico</li>
              <li>Brazil</li>
              <li>Spain</li>
              <li>UK</li>
              <li>France</li>
              <li>Netherlands</li>
              <li>Germany</li>
              <li>Italy</li>
              <li>Sweden</li>
              <li>Poland</li>
              <li>Egypt</li>
              <li>Turkey</li>
              <li>Saudi Arabia </li>
              <li>United Arab Emirates </li>
              <li>India</li>
              <li>Singapore</li>
              <li>Australia</li>
              <li>Japan</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Col>
      <Col className="pb-2">
        <Accordion.Item eventKey="204">
          <Accordion.Header>What AZSzero does not provide?</Accordion.Header>
          <Accordion.Body>
            Features that AZSzero Doesn't provide:
            <ul>
              <li>PPC integration. (On the Roadmap)</li>
              <li>Products and keywords research. (Planned)</li>
              <li>Competitors and Vendors research. (Not Planned)</li>
              <li>Email Campaigns. (Not Planned)</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Col>
    </Accordion>
  )
}

export default Azszeroapp

import React from "react"
import { Col, Accordion } from "react-bootstrap"

const DataProtection = () => {
  return (
    <Accordion>
      <Col className="pb-2">
        <Accordion.Item eventKey="800">
          <Accordion.Header>Is my Data Secure?</Accordion.Header>
          <Accordion.Body>
            <p>
              The system accesses your Seller account frequently to collect data
              on multiple topics and reports, then crunches the data to convert
              it into far more useful than just an excel report that you
              download from the Seller Central.
            </p>
            <p>Your information is not viewed or shared with anyone else.</p>
          </Accordion.Body>
        </Accordion.Item>
      </Col>

      <Col className="pb-2">
        <Accordion.Item eventKey="801">
          <Accordion.Header>
            Do you make any changes to my Amazon Seller Account?
          </Accordion.Header>
          <Accordion.Body>
            The system type of access is READ-ONLY, which means we do not write
            or modify anything on your amazon seller account.
          </Accordion.Body>
        </Accordion.Item>
      </Col>
      <Col className="pb-2">
        <Accordion.Item eventKey="802">
          <Accordion.Header>
            What Data do you access on my behalf?
          </Accordion.Header>
          <Accordion.Body>
            AZSzero will access the following reports:
            <ul>
              <li>Inventory</li>
              <li>Orders</li>
              <li>Refunds</li>
              <li>Feedback</li>
              <li>FBA Shipments</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Col>
      <Col className="pb-2">
        <Accordion.Item eventKey="803">
          <Accordion.Header>Where is my data located?</Accordion.Header>
          <Accordion.Body>
            The system saves your data into our cloud database provider which
            has multiple security layers preventing any unauthorized access.
          </Accordion.Body>
        </Accordion.Item>
      </Col>
    </Accordion>
  )
}

export default DataProtection
